/*
 * @Author: yuwenwen
 * @Date: 2022-12-05 15:39:42
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-02-09 16:05:44
 * @FilePath: \nc-funeral-mobile\src\views\obituary\template\template.js
 */
import {
    getImagesUrl,
    wxShare
} from "@/utils/funeral.js";
import {
    getObituaryDetails,
    addObituaryContent,
    getCommentsList
} from "@/api/obituary.js";
import {
    showToast
} from "vant";
export default {
    data() {
        return {
            template: {
                templateId: "",
                templatePath: "",
            },
            type: "",
            previewId: "",
            templateInfo: {
                ceremonyAddress: "江西省南昌市新建区松鹤路723号",
                ceremonyDay: "2022年10月30日",
                ceremonyPlace: "南昌殡仪馆仙鹤厅",
                ceremonyTime: "上午7时30分",
                contactNumber: "400-000-1448",
                deadAge: "80",
                deadDay: "2022年10月25日",
                deadTime: "中午12时15分",
                inviter: "妻 姚莉 携 儿女子孙",
                title: "追思告别仪式",
                deadName: "范平宽",
                deadImg: require("../../../assets/images/obituary/template1/default-avatar.png"),
            },

            messageList: [{
                avatar: require('../../../assets/images/obituary/avatar.png'),
                username: '南笙依梦',
                createTime: '2022-10-29 09:21:32',
                content: '生命来来去去，这是常态，不管是老人，还是年轻人，都会面临着亲人的逝去。'
            }, {
                avatar: require('../../../assets/images/obituary/avatar1.png'),
                username: '北栀',
                createTime: '2022-10-28 04:31:02',
                content: '亲⼈失去痛离别，苦为⼈之湿短袖。不知何时再相见，天上⼈间⽉满花。'
            }, {
                avatar: require('../../../assets/images/obituary/avatar2.png'),
                username: '清茶渺音',
                createTime: '2022-10-27 01:14:06',
                content: '亲⼈去世了，不要太伤⼼难过，他不会喜欢你这样消沉的，打起精神，努⼒吧！'
            }, {
                avatar: require('../../../assets/images/obituary/avatar3.png'),
                username: '静待唯美',
                createTime: '2022-09-26 08:39:48',
                content: '⼈死不能复⽣，我知道你伤⼼，难过，但是⽇⼦还是要照样过。'
            }],

            Pagination:{
                currentPage:1, //当前页
                pageCount:'', //总页数
                itemsPerPage:5, //每页条数
            },
        }
    },
    computed: {
        obituaryContent: {
            get() {
                return this.$store.state.user.obituaryContent;
            },
        },
        isPreview: {
            get() {
                return this.$route.query.id;
            }
        }

    },
    watch: {
        isPreview(val) {
            return val;
        }
    },
    created() {
        if (this.$route.query.id) {
            this.previewId = this.$route.query.id;
            this.getObituaryDetails(this.previewId);
            this.getCommentsList(this.previewId);
        } else {
            let {
                type
            } = this.$route.query;
            this.type = type;
            this.template = {
                ...this.$route.query
            };
            if (type == 'preview') {
                this.getPreviewData();
            }

        }
    },
    mounted() {
        const _this = this;
        this.$nextTick(() => {
            _this.getMapInfo();
        });
    },
    methods: {
        // 获取预览数据
        getPreviewData() {
            
            this.templateInfo = {
                ...this.obituaryContent,
                deadImg:this.obituaryContent.deadImg?getImagesUrl(this.obituaryContent.deadImg):''
            };
            console.log(this.templateInfo)
        },
        //  获取讣告详情
        async getObituaryDetails(id) {
            let [err, res] = await this.$awaitWrap(getObituaryDetails(id));
            if (err) {
                return;
            }
            this.templateInfo = {
                ...res.result
            };
            if (this.templateInfo.deadImg) {
                this.templateInfo.deadImg = getImagesUrl(this.templateInfo.deadImg);
            }

            this.initWxShareParams();
        },

        // 获取留言列表
        async getCommentsList(id) {
            let data = {
                contentId: id || this.previewId,
                pageNo:this.Pagination.currentPage,
                pageSize:this.Pagination.itemsPerPage,
            }
            let [err, res] = await this.$awaitWrap(getCommentsList(data));
            if (err) {
                return;
            }
            if (res.code == 200) {
                this.messageList = res.result.records;
                this.Pagination.pageCount = res.result.pages   //总页数
            }
        },

        // 留言页码改变触发
        pageChange(val){
            this.Pagination.currentPage = val;
            this.getCommentsList(this.previewId);
        },



        //  微信分享
        initWxShareParams() {
            let config = {
                title: `${this.templateInfo.deadName + this.templateInfo.title}`,
                desc: `${this.templateInfo.inviter}`,
                link: window.location.href,
                imgUrl: require("../../../assets/images/obituary/share-icon.png"),
            };
            wxShare(config);
        },

        // 数据校验
        dataValidte(keyList, formData) {
            return new Promise((resove) => {
                keyList.forEach((item) => {
                    if (formData[item.key] == null || formData[item.key] == '') {
                        showToast(item.text + '不能为空');
                        resove(false)
                    }
                    const reg = /^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-8]|18[0-9]|19[0-9])\d{8}$/;
                    if(formData.contactNumber){
                        if (!reg.test(formData.contactNumber)) {
                            showToast('联系电话格式有误');
                            resove(false)
                        }
                    }
                    
                })
                resove(true)
            })

        },

        //  保存
        async saveData() {
            // 数据校验
            let keyList = [{
                    key: 'contactNumber',
                    text: '联系电话'
                }, {
                    key: 'ceremonyAddress',
                    text: '仪式地址'
                }, {
                    key: 'ceremonyDay',
                    text: '仪式日期'
                }, {
                    key: 'ceremonyTime',
                    text: '仪式时间'
                }, {
                    key: 'deadName',
                    text: '逝者名称'
                }, {
                    key: 'title',
                    text: '讣告标题'
                },

            ]
            this.dataValidte(keyList, this.obituaryContent).then((flag) => {
                console.log(flag)
                if (flag) {
                    addObituaryContent({
                        ...this.obituaryContent
                    }).then(res => {
                        if (res.code == 200) {
                            console.log(res);
                            this.getObituaryDetails(res.result.id);
                            this.getCommentsList(res.result.id);
                            this.previewId = res.result.id;
                            this.$router.replace({
                                path: res.result.path,
                                query: {
                                    id: res.result.id
                                },
                            });
                        }
                    })
                }
            });
        },
        //  写留言页面
        toLeaveMessage() {
            this.$router.push({
                path: "/obituary/leave/message",
                query: {
                    id: this.templateInfo.id
                },
            });
        },

        // 上一步
        back() {
            this.$router.go(-1);
        },

        getMapInfo() {
            let Bmap = window.BMapGL;
            this.map = new Bmap.Map("navi-container");
            // 初始化地图，设置中心点坐标和地图级别
            const point = new Bmap.Point(115.842079, 28.716471);
            const marker = new Bmap.Marker(point); // 创建标注
            this.map.addOverlay(marker); // 将标注添加到地图中
            const content = "南昌市殡仪馆";
            const label = new Bmap.Label(content, {
                // 创建文本标注
                position: point, // 设置标注的地理位置
                offset: new Bmap.Size(-45, 5), // 设置标注的偏移量
            });
            label.setStyle({
                // 设置label的样式
                color: "#000",
                background: "transparent",
                fontSize: "15px",
                border: "none",
            });
            this.map.addOverlay(label); // 将标注添加到地图中
            this.map.centerAndZoom(point, 15);
            this.map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
        },
        //填写讣告内容 
        toAddContent() {
            this.$router.push({
                path: "/obituary/add/content",
                query: {
                    ...this.template
                },
            });
        },
    },
}