/*
 * @Author: yuwenwen
 * @Date: 2022-11-30 15:09:38
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-01-06 15:00:07
 * @FilePath: \nc-funeral-mobile\src\api\obituary.js
 */
import request from "../utils/request";

const obituary = '/h5/bus/obituary/';


// 获取模板列表
export function getObituaryTempList(){
    return request({
        url:obituary + `template/list`,
        method:'GET',
    });
}

// 模板详情
export function getObituaryTempDetails(id){
    return request({
        url:obituary + `template/queryById/${id}`,
        method:'GET'
    });
}

// 生成讣告
export function addObituaryContent(data){
    return request({
        url:obituary + `add/content`,
        method:'POST',
        data
    });
}

// 讣告记录
export function getObituaryRecords(params){
    return request({
        url:obituary + `content/list`,
        method:'GET',
        params
    })
}

// 讣告详情
export function getObituaryDetails(id){
    return request({
        url:obituary + `content/queryById/${id}`,
    })
}

// 讣告留言
export function addObituaryComments(data){
    return request({
        url:obituary + `add/message`,
        method:'POST',
        data
    })
}

// 讣告留言列表
export function getCommentsList(params){
    return request({
        url:obituary + `list/message`,
        method:'GET',
        params
    })
}